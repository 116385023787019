import './App.css';
import './style/Estilo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagina from './components/Pagina';

function App() {
  return (
    <div className="App">
      <Pagina />
    </div>
  );
}

export default App;
