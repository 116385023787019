import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Cabecalho from './Cabecalho';

function Pagina() {

	const [dataCW, setDataCW] = useState(null);
	const listenerAdded = useRef(false);

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.origin === 'https://w29.unigate.com.br') {
				try {
					const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

					if (data && data.data) {
						// Verifica se os dados são diferentes antes de atualizar o estado
						if (JSON.stringify(data) !== JSON.stringify(dataCW)) {
							setDataCW(data);
						}
					} else {
						setDataCW(null);
						// setError("Erro: reinicia a página.");
					}
				} catch (error) {
					console.error('Erro ao analisar os dados:', error);
					setDataCW(null);
				}
			} else {
				// setError("Reinicie a página.");
				console.log('Mensagem recebida de origem não esperada:', event.origin);
			}
		};

		if (!listenerAdded.current) {
			console.log("Listener adicionado");
			window.addEventListener('message', handleMessage);
			listenerAdded.current = true;
		}

		return () => {
			if (listenerAdded.current) {
				window.removeEventListener('message', handleMessage);
				listenerAdded.current = false;
			}
		};
	}, []); // Executa apenas uma vez quando o componente é montado

	const [cliente, setCliente] = useState({
		nome: '',
		cpf: '',
		email: '',
		telefone: '',
		lgpd: false, //false ou true?
		observacoes: ''
	});

	const [endereco, setEndereco] = useState({
		cep: '',
		estado: '',
		cidade: '',
		logradouro: '',
		bairro: '',
		numero: '',
		complemento: ''
	});

	const [marketing, setMarketing] = useState({
		campanha: '',
		captacao: '',
		tipoContato: '',
		engajamento: '',
		canalEntrada: '',
		classificacaoConversa: '',
		funilVendas: '',
		tentativa: ''
	});

	// const [error, setError] = useState(null);

	return (
		<div>
			<Router
				future={{
					v7_startTransition: true,
					v7_relativeSplatPath: true,
				}}
			>
				{/* {dataCW && <Cabecalho dados={[cliente, endereco, marketing]} cliente={cliente} setCliente={setCliente} endereco={endereco} setEndereco={setEndereco} marketing={marketing} setMarketing={setMarketing} dataCW={dataCW} />} */}
				{<Cabecalho
					dados={[cliente, endereco, marketing]}
					cliente={cliente}
					setCliente={setCliente}
					endereco={endereco} setEndereco={setEndereco}
					marketing={marketing}
					setMarketing={setMarketing}
					dataCW={dataCW} />}
			</Router>
			{/* {error && <p>Error: {error}</p>} */}
		</div>
	);
}

export default Pagina;
