import React from "react";
import Form from 'react-bootstrap/Form';

function Marketing({ marketing, setMarketing }) {

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMarketing({
      ...marketing,
      [name]: value
    });
  };

  return (
    <div>
      <div id="Form">
        <Form>
          <div className="FormContainer">
            <Form.Group style={{ width: "100%", margin: "4px" }}>
              <Form.Label>Campanha</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={marketing.campanha}
                name="campanha"
                type="text"
                placeholder=""
              />
            </Form.Group>
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposMarketing">
              <Form.Label>Captação</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={marketing.captacao}
                name="captacao" >
                <option value="listas">listas</option>
                <option value="base-empresa">base-empresa</option>
                <option value="meta-ads">meta-ads</option>
                <option value="google-ads">google-ads</option>
                <option value="indicacao">indicacao</option>
                <option value="nao-aplica">nao-aplica</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="CamposMarketing">
              <Form.Label>Tipo do contato</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={marketing.tipoContato}
                name="tipoContato">
                <option value="lead">lead</option>
                <option value="cliente">cliente</option>
                <option value="interno">interno</option>
                <option value="prestador">prestador</option>
                <option value="pessoal">pessoal</option>
                <option value="bloqueado">bloqueado</option>
                <option value="nao-aplica">nao-aplica</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposMarketing">
              <Form.Label>Engajamento</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={["passivo", "moderado", "avancado", "nenhum"].includes(marketing.engajamento) ? marketing.engajamento : "nenhum"}
                name="engajamento">
                <option value="passivo">passivo</option>
                <option value="moderado">moderado</option>
                <option value="avancado">avancado</option>
                <option value="nenhum">nenhum</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="CamposMarketingTexto">
              <Form.Label>Canal de Entrada</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={marketing.canalEntrada}
                name="canalEntrada" disabled type="number" min="0" placeholder="" />
            </Form.Group>

            {/* <Form.Group className="CamposMarketing">
              <Form.Label>Operadora Fidelizado</Form.Label>
              <Form.Select>
                <option value="claro">claro</option>
                <option value="tim">tim</option>
                <option value="oi">oi</option>
                <option value="algar">algar</option>
                <option value="vero">vero</option>
                <option value="nao-aplica">nao-aplica</option>
              </Form.Select>
            </Form.Group> */}
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposMarketingTexto">
              <Form.Label>Classificação da conversa</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={["comercial", "interna", "prestador", "pessoal", "nao-aplica"].includes(marketing.classificacaoConversa) ? marketing.classificacaoConversa : ""}
                name="classificacaoConversa"
              >
                {marketing.classificacaoConversa ?? <option value="">Selecione uma opção</option>}
                <option value="comercial">comercial</option>
                <option value="interna">interna</option>
                <option value="prestador">prestador</option>
                <option value="pessoal">pessoal</option>
                <option value="nao-aplica">nao-aplica</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="CamposMarketingTexto">
              <Form.Label>Funil de Vendas</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={marketing.funilVendas}
                name="funilVendas"
                disabled >
                <option value="contato-auto">contato-auto</option>
                <option value="contato-manual">contato-manual</option>
                <option value="em-atendimento">em-atendimento</option>
                <option value="em-negociacao">em-negociacao</option>
                <option value="documentacao">documentacao</option>
                <option value="venda-realizada">venda-realizada</option>
                <option value="venda-perdida">venda-perdida</option>
                <option value="fidelizado">fidelizado</option>
                <option value="nao-aplica">nao-aplica</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposMarketingTexto">
              <Form.Label>Tentativa</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={marketing.tentativa}
                name="tentativa" type="number" min="0" placeholder="" />
            </Form.Group>
          </div>

          {/* <div className="FormContainer">

          <Form.Group className="CamposMarketingTexto">
              <Form.Label>Produto e Valor Fidelizado</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            
            <Form.Group className="CamposMarketing">
              <Form.Label>Termino de Fidelidade</Form.Label>
              <Form.Control type="date" placeholder="" />
            </Form.Group>
          </div> */}

        </Form>
      </div>

    </div>
  )
}

export default Marketing;