import React from 'react';
import Form from 'react-bootstrap/Form';

function Cliente({ cliente, setCliente }) {

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (type === 'checkbox') {
  //     setCliente({
  //       ...cliente,
  //       [name]: checked
  //     });
  //   } else {
  //     setCliente({
  //       ...cliente,
  //       [name]: value
  //     });
  //   }
  // };

  // const handleChange = (e) => {
  //   let { name, value, type, checked } = e.target;
  //   if (type === 'checkbox') {
  //     setCliente({
  //       ...cliente,
  //       [name]: checked
  //     });
  //     return
  //   }
  //   setCliente({
  //     ...cliente,
  //     [name]: value
  //   });

  return (
    <div>
      <div id="Form">
        <Form>
          <div className="FormContainer">
            <Form.Group className="CamposInfos">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                //onChange={handleChange}
                //value={cliente.nome}
                name="nome"
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>CPF</Form.Label>
              <Form.Control
                // value={cliente.cpf} 
                //onChange={handleChange}
                name="cpf" type="text" />
            </Form.Group>

          </div>

          <div className="FormContainer">
            <Form.Group className="CamposInfos">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                // value={cliente.email} 
                //onChange={handleChange}
                name="email"
                type="email"
                placeholder="" />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                //value={cliente.telefone}
                //onChange={handleChange}
                name="telefone"
                type="tel"
                placeholder="" />
            </Form.Group>

          </div>

          {/* <h4 className="SubTitulo mt-5">Produto</h4>
          <div className="FormContainer2">
            <Form.Group className="CamposInfos">
              <Form.Select>
                <option value=""></option>
              </Form.Select>
            </Form.Group>
          </div> */}

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px", justifyContent: "center" }} >
            <h4 >LGPD</h4>
            <Form.Group name="bloqueado" style={{ display: "flex", alignItems: "center", textAlign: "center", margin: "20px" }}>
              <Form.Check
                //checked={cliente.bloqueado}
                //onChange={handleChange}
                style={{ width: "12vh" }}
                type="checkbox"
                label="Bloqueado"
                name="bloqueado" />
            </Form.Group>
          </div>

          <div className="FormContainer2 mt-2">
            <Form.Group className="CamposInfos">
              <Form.Label>Observações</Form.Label>
              <Form.Control
                // value={cliente.observacoes} 
                //onChange={handleChange}
                name="observacoes"
                as="textarea"
                style={{ height: "100%" }} />
            </Form.Group>
          </div>

        </Form>
      </div>
    </div>
  );
}

export default Cliente;
