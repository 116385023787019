import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Marketing from './dados/Marketing';
import Cliente from './dados/Cliente';
import Endereco from './dados/Endereco';

function Cabecalho({ cliente, setCliente, endereco, setEndereco, marketing, setMarketing, dataCW }) {

	const location = useLocation();
	const isActive = (path) => location.pathname === path;

	const [data, setData] = useState(null); // Estado para armazenar a resposta da API
	const [error, setError] = useState(null); // Estado para armazenar erros, se houver

	// useEffect(() => {
	// Função assíncrona para realizar a requisição
	const fetchData = async () => {
		try {
			const response = await fetch('https://enginewebhook.w38.unigate.com.br/webhook/nery-consultar-campos', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					// Dados que você quer enviar no body da requisição POST
					// contact_id: dataCW.data.contact.id,
					// conversation_id: dataCW.data.conversation.id
					contact_id: 4257,
					conversation_id: 4357
				})
			});
			const result = await response.json(); // Pega a resposta como texto
			console.log(result); // Verifica o conteúdo da resposta

			if (!response.ok) {
				throw new Error(`Erro na requisição: ${response.status}`);
			}

			if (!result.data) {
				setData('sem')
				return
			}
			setData(result)
			setCliente(result.cliente)
			setEndereco(result.endereco)
			setMarketing(result.data.marketing)

		} catch (err) {
			setError(err.message); // Armazena o erro no estado 'error' caso a requisição falhe
		}
	};

	// fetchData(); // Chama a função para realizar a requisição

	// }, []); // Executa apenas uma vez quando o componente é montado

	return (
		<div>
			<Navbar data-bs-theme="dark" expand="sm" className="bg-body-tertiary custom-navbar">
				<Container>
					{/* <img
							alt=""
							src="/img/logo.svg"
							width="30"
							height="30"
							className="d-inline-block align-top"
						/> 
						<Navbar.Brand>Marca</Navbar.Brand>
						*/}

					<Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" />
					<Navbar.Collapse>
						<Nav>
							<Nav.Link
								as={Link}
								to="/cliente"
								className={isActive('/cliente') ? 'active' : ''}
							>
								Cliente
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/Endereco"
								className={isActive('/Endereco') ? 'active' : ''}
							>
								Endereço
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/Marketing"
								className={isActive('/Marketing') ? 'active' : ''}
							>
								Marketing
							</Nav.Link>
							<Button onClick={async () => { await fetchData() }} >load</Button>
						</Nav>

						{data && (
							<Nav className="ms-auto NavContainer" >
								<Navbar.Brand id="IdControle">
									ID de controle: {data.data.id_controle}
								</Navbar.Brand>
								<Button variant="light">Salvar</Button>
							</Nav>
						)}

					</Navbar.Collapse>
				</Container>
			</Navbar>

			<Container>
				<Routes>
					<Route path="/" element={<Navigate to="/cliente" />} />
					<Route path="/Cliente" element={<Cliente cliente={cliente} setCliente={setCliente} />} />
					<Route path="/Endereco" element={<Endereco endereco={endereco} setEndereco={setEndereco} />} />
					{data && <Route path="/Marketing" element={<Marketing marketing={marketing} setMarketing={setMarketing} />} />}
				</Routes>
			</Container>
		</div>
	)
}

export default Cabecalho;
