import React, { useState } from "react";
import Form from 'react-bootstrap/Form';

function Endereco() {
  const [endereco, setEndereco] = useState({
    cep: "",
    estado: "",
    cidade: "",
    logradouro: "",
    bairro: "",
    numero: "",
    complemento: "",
  });

  const fetchCepData = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setEndereco((prevEndereco) => ({
          ...prevEndereco,
          estado: data.estado,
          cidade: data.localidade,
          logradouro: data.logradouro,
          bairro: data.bairro,
        }));
      } else {
        alert("CEP não encontrado.");
      }
    } catch (error) {
      alert("Erro ao buscar CEP.");
    }
  };

  const handleCepChange = (e) => {
    const cepInput = e.target.value.replace(/\D/g, "");
    setEndereco((prevEndereco) => ({ ...prevEndereco, cep: cepInput }));

    if (cepInput.length === 8) {
      fetchCepData(cepInput);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEndereco((prevEndereco) => ({ ...prevEndereco, [name]: value }));
  };

  return (
    <div>
      <div id="Form">
        <Form>

          <div className="FormContainer">
            <Form.Group className="CamposInfos">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={endereco.cep}
                onChange={handleCepChange}
              />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>Estado</Form.Label>
              <Form.Control
                type="text"
                name="estado"
                value={endereco.estado}
                onChange={handleChange}
                disabled
              />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                type="text"
                name="cidade"
                value={endereco.cidade}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposInfos">
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="CamposInfos">
              <Form.Label>Número</Form.Label>
              <Form.Control
                type="text"
                name="numero"
                value={endereco.numero}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="FormContainer">
            <Form.Group className="CamposInfos">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                name="complemento"
                value={endereco.complemento}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

        </Form>
      </div>
    </div>
  );
}

export default Endereco;
