// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
  background-color: rgb(239, 250, 241);
}

#Form {
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: 25px;
}

.FormContainer {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  gap: 10px;
}

.CamposInfos {
  margin: 5px;
  flex: 1 1 35%;
  width: 500%;
}

.CamposMarketing {
  margin: 5px;
  min-width: 200px;
  max-width: 400px;
}

.CamposMarketingTexto {
  margin: 5px;
  width: 200px;
}

.FormContainer2 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  min-width: 300px;
  width: 100%;
}

#Titulo,
.SubTitulo {
  margin-top: 15px;
}

#IdControle {
  color: white;
  vertical-align: middle;
  margin-top:auto;
  margin-bottom:auto;
  font-size: medium;
}

.custom-navbar .nav-link{
  border-radius: 8px;
}

.custom-navbar .nav-link:hover {
  color: #85d4cb;
  background-color: rgb(95, 94, 94);
  border-radius: 8px;
}

.custom-navbar .nav-link.active {
  color: #a3eee1!important; 
}`, "",{"version":3,"sources":["webpack://./src/style/Estilo.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["html, body, #root {\r\n  background-color: rgb(239, 250, 241);\r\n}\r\n\r\n#Form {\r\n  display: grid;\r\n  place-items: center;\r\n  width: 100%;\r\n  margin-top: 25px;\r\n}\r\n\r\n.FormContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  min-width: 300px;\r\n  width: 100%;\r\n  gap: 10px;\r\n}\r\n\r\n.CamposInfos {\r\n  margin: 5px;\r\n  flex: 1 1 35%;\r\n  width: 500%;\r\n}\r\n\r\n.CamposMarketing {\r\n  margin: 5px;\r\n  min-width: 200px;\r\n  max-width: 400px;\r\n}\r\n\r\n.CamposMarketingTexto {\r\n  margin: 5px;\r\n  width: 200px;\r\n}\r\n\r\n.FormContainer2 {\r\n  display: flex;\r\n  justify-content: center;\r\n  flex-direction: row;\r\n  text-align: center;\r\n  min-width: 300px;\r\n  width: 100%;\r\n}\r\n\r\n#Titulo,\r\n.SubTitulo {\r\n  margin-top: 15px;\r\n}\r\n\r\n#IdControle {\r\n  color: white;\r\n  vertical-align: middle;\r\n  margin-top:auto;\r\n  margin-bottom:auto;\r\n  font-size: medium;\r\n}\r\n\r\n.custom-navbar .nav-link{\r\n  border-radius: 8px;\r\n}\r\n\r\n.custom-navbar .nav-link:hover {\r\n  color: #85d4cb;\r\n  background-color: rgb(95, 94, 94);\r\n  border-radius: 8px;\r\n}\r\n\r\n.custom-navbar .nav-link.active {\r\n  color: #a3eee1!important; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
